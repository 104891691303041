// @flow
import 'core-js/stable';
import 'whatwg-fetch';
// $FlowIgnore
import { AnalyticsProvider } from 'utils/src/hooks/useAnalytics';
import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
// $FlowIgnore
import { AuthenticationProvider } from './data/auth/transition';
// $FlowIgnore
import 'react-components/src/utils/styles';
import current from './config';
import App from './App';
import packageJSON from '../package.json';
import * as serviceWorker from './serviceWorker';

import './css/App.css';
import 'antd/dist/antd.css';

if (current.track) {
  Sentry.init({
    dsn: current.sentry.dsn,
    release: `employee@${packageJSON.version}`,
    environment: current.sentry.environment,
    attachStacktrace: true,
    denyUrls: ['localhost'],
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    ignoreErrors: [/ResizeObserver/],

    tracesSampleRate: 1.0,
  });

  LogRocket.init(current.logrocket.id);
}

const Root = () => {
  return (
    <Sentry.ErrorBoundary fallback="An error has occured">
      <AnalyticsProvider gtagId={current.gtag?.id}>
        <AuthenticationProvider
          redirectUri={window.location.origin}
          scope="openid profile email"
          cacheLocation="localstorage"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...current.auth}
        >
          <StrictMode>
            <App />
          </StrictMode>
        </AuthenticationProvider>
      </AnalyticsProvider>
    </Sentry.ErrorBoundary>
  );
};
ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
