/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RootLayoutFragment$ref: FragmentReference;
declare export opaque type RootLayoutFragment$fragmentType: RootLayoutFragment$ref;
export type RootLayoutFragment = {|
  +viewer: ?{|
    +hasHSA: boolean,
    +canLinkBankAccount: boolean,
    +benepassCashAccount: ?string,
  |},
  +$refType: RootLayoutFragment$ref,
|};
export type RootLayoutFragment$data = RootLayoutFragment;
export type RootLayoutFragment$key = {
  +$data?: RootLayoutFragment$data,
  +$fragmentRefs: RootLayoutFragment$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RootLayoutFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasHSA",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canLinkBankAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "benepassCashAccount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5504ba0e8e3c919e9271ff7a121b443c';

module.exports = node;
