// @flow
declare var process: any;

const test = {
  test: true,
  track: false,
  sentry: {
    dsn: '',
  },
  auth: {
    domain: 'auth.benefitsapi.com',
    clientId: 'GcKuehS4eYHR9Bo2T70ffovSMGsXRavm',
    audience: 'https://api.benefitsapi.com',
  },
  api: {
    origin: 'http://benefits_api.com/',
    graphql: 'http://benefits_api.com/graphql',
  },
  plaid: {
    origin: 'http://link_benepass.com/',
  },
  cms: { origin: 'http://cms_api.com/' },
  street: {
    autocomplete: 'https://us-autocomplete-pro.api.smartystreets.com',
  },
};

const staging = {
  track: true,
  auth: {
    domain: 'benefitsapi-dev.auth0.com',
    clientId: 'TfcPt4awj6CT419Fsp6OKXHp4mWrsmDs',
    audience: 'testing',
  },
  api: {
    origin: process.env.REACT_APP_BENEFITS_API || 'https://staging.benefitsapi.com/',
    graphql: process.env.REACT_APP_GRAPHQL || 'https://staging.benefitsapi.com/graphql',
  },
  cms: {
    origin: process.env.REACT_APP_CMS_API || 'https://cms.benefitsapi.com/',
  },
  plaid: {
    origin: 'https://link.development.getbenepass.com/',
  },
  street: {
    autocomplete: 'https://us-autocomplete-pro.api.smartystreets.com',
  },
  sentry: {
    dsn: 'https://f467ef38040a4a778c84a5375550e44b@o463927.ingest.sentry.io/5656326',
    environment: 'staging',
  },
  logrocket: {
    id: 'wrqwym/employees-webapp-staging',
  },
};

const production = {
  track: (process.env.REACT_APP_TRACK_SENTRY ?? '1') === '1',
  auth: {
    domain: 'cognito.benefitsapi.com',
    client_id: '6l7jeu4r44kgndgeab4aot355m',
    redirect_uri: 'https://app.getbenepass.com/?v=2',
    client: 'employeeWebApp',
    signOnUrl: 'https://signon.benefitsapi.com/',
  },
  api: {
    origin: process.env.REACT_APP_BENEFITS_API || 'https://api.benefitsapi.com/t/',
    graphql: process.env.REACT_APP_GRAPHQL || 'https://api.benefitsapi.com/t/graphql',
  },
  cms: {
    origin: process.env.REACT_APP_CMS_API || 'https://cms.benefitsapi.com/',
  },
  plaid: {
    origin: 'https://link.getbenepass.com',
  },
  street: {
    autocomplete: 'https://us-autocomplete-pro.api.smartystreets.com',
  },
  sentry: {
    dsn: 'https://f467ef38040a4a778c84a5375550e44b@o463927.ingest.sentry.io/5656326',
    environment: 'production',
  },
  logrocket: {
    id: 'wrqwym/employees-webapp-production',
  },
  gtag: {
    id: 'G-0M24ZSMB0N',
  },
};

const potemkin = {
  track: false,
  auth: {
    domain: 'auth.benefitsapi.com',
    clientId: 'KYpqOTctVBso2IYD3c3cBlWaqhVAAdKY',
    audience: 'api.benefitsapi.com',
  },
  api: {
    origin: process.env.REACT_APP_BENEFITS_API || 'https://api.benefitsapi.com/',
    graphql: process.env.REACT_APP_GRAPHQL || 'https://api.demo.getbenepass.com/graphql',
  },
  segmentKey: process.env.REACT_APP_SEGMENT_KEY,
  cms: {
    origin: process.env.REACT_APP_CMS_API || 'https://cms.benefitsapi.com/',
  },
  plaid: {
    origin: 'https://link.development.getbenepass.com/',
  },
  street: {
    autocomplete: 'https://us-autocomplete-pro.api.smartystreets.com',
  },
};

const environment = {
  test,
  staging,
  production,
  potemkin,
};

const currentConfig: any = environment[process.env.REACT_APP_ENVIRONMENT] ?? production;

export default currentConfig;
