/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RootLayoutFragment$ref = any;
export type routesQueryVariables = {||};
export type routesQueryResponse = {|
  +viewer: ?{|
    +apiID: string,
    +fullName: string,
    +email: string,
  |},
  +$fragmentRefs: RootLayoutFragment$ref,
|};
export type routesQuery = {|
  variables: routesQueryVariables,
  response: routesQueryResponse,
|};
*/


/*
query routesQuery {
  viewer {
    apiID
    fullName
    email
    id
  }
  ...RootLayoutFragment
}

fragment RootLayoutFragment on Query {
  viewer {
    hasHSA
    canLinkBankAccount
    benepassCashAccount
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apiID",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RootLayoutFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasHSA",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canLinkBankAccount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "benepassCashAccount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "712510ed839016b6c708c1bff7b6c926",
    "id": null,
    "metadata": {},
    "name": "routesQuery",
    "operationKind": "query",
    "text": "query routesQuery {\n  viewer {\n    apiID\n    fullName\n    email\n    id\n  }\n  ...RootLayoutFragment\n}\n\nfragment RootLayoutFragment on Query {\n  viewer {\n    hasHSA\n    canLinkBankAccount\n    benepassCashAccount\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c36c758d998d8e94091aa1bfde30939b';

module.exports = node;
