// @flow
import type { Node } from 'react';
import Colors from '../../utils/Colors';
import FontColor from '../../utils/FontColor';
import FontSize from '../../utils/FontSize';

const FontWeight = {
  NORMAL: 'normal',
  MEDIUM: 'medium',
  SEMIBOLD: 'semibold',
  BOLD: 'bold',
};

const Cases = {
  CAMELCASE: 'camelcase',
  LOWERCASE: 'lowercase',
  UPPERCASE: 'uppercase',
};

type Props = {
  children: string | Node,
  color?: string, // if applying size, color will override size's color default
  size?: string, // size also applies default colors based on design guide
  style?: {},
  textCase?: 'uppercase' | 'lowercase' | 'camelcase',
  truncateLines?: number,
  weight?: string,
  className?: string,
};

const StyledText = (props: Props): Node => {
  const { children, color, size, style, textCase, truncateLines, weight, className } = props;
  const userStyles = style || {};

  const useSize = size || FontSize.BODY3;

  // Default styles
  let text = children;
  const configStyles: any = {
    fontSize: 14,
    letterSpacing: null,
    // lineHeight: 17,
    color: Colors.black,
  };

  // TODO: if truncating, tap to see full value
  let numberOfLines = null;
  if (truncateLines) {
    numberOfLines = truncateLines;
    configStyles.flex = 1;
  }

  // Design Style Guide has font types
  switch (useSize) {
    case FontSize.H1:
      configStyles.fontSize = 40;
      configStyles.lineHeight = 48;
      configStyles.fontWeight = 'bold';
      break;
    case FontSize.H1B:
      configStyles.fontSize = 32;
      configStyles.lineHeight = 38;
      configStyles.fontWeight = 'bold';
      break;
    case FontSize.H2:
      configStyles.fontSize = 29;
      configStyles.lineHeight = 35;
      configStyles.fontWeight = 600;
      break;
    case FontSize.H3:
      configStyles.fontSize = 28;
      configStyles.lineHeight = 34;
      configStyles.fontWeight = 500;
      break;
    case FontSize.H4:
      configStyles.fontSize = 23;
      configStyles.lineHeight = 28;
      configStyles.fontWeight = 500;
      break;
    case FontSize.H5:
      configStyles.fontSize = 20;
      configStyles.lineHeight = 24;
      break;
    case FontSize.H6:
      // This is a weird hack, like the same as H4 but with lineHeight
      configStyles.fontSize = 18;
      configStyles.lineHeight = 24;
      configStyles.color = Colors.black;
      configStyles.letterSpacing = '-0.01em';
      break;
    case FontSize.BODY1:
      configStyles.fontSize = 24;
      configStyles.fontWeight = 29;
      configStyles.fontWeight = 600;
      break;
    case FontSize.BODY2:
      configStyles.fontSize = 21;
      configStyles.lineHeight = 25;
      configStyles.fontWeight = 600;
      break;
    case FontSize.BODY3:
      configStyles.fontSize = 18;
      configStyles.lineHeight = 22;
      break;
    case FontSize.BODY4:
      configStyles.fontSize = 16;
      configStyles.lineHeight = 20;
      break;
    case FontSize.BODY5:
      configStyles.fontSize = 14;
      configStyles.lineHeight = 18;
      break;
    case FontSize.BODY6:
      configStyles.fontSize = 48;
      configStyles.lineHeight = 58;
      configStyles.fontWeight = 600;
      break;
    case FontSize.BODY7:
      configStyles.fontSize = 44;
      configStyles.lineHeight = 53;
      configStyles.fontWeight = 600;
      break;
    case FontSize.BODY8:
      configStyles.fontSize = 26;
      configStyles.lineHeight = 32;
      configStyles.fontWeight = 600;
      break;
    case FontSize.BODY9:
      configStyles.fontSize = 10;
      configStyles.lineHeight = 14;
      configStyles.fontWeight = 100;
      break;
    default:
      break;
  }

  // Set color, case, and weight after setting size
  // because size param also sets colors as default pairs
  // NOTE: color names in FontColor are deprecated. Check both for now.
  switch (color) {
    case FontColor.HEADER:
      configStyles.color = Colors.headerText;
      break;
    case FontColor.BLUE_BRIGHT:
      configStyles.color = Colors.blueLight;
      break;
    case FontColor.LINK:
      configStyles.color = Colors.headerButton;
      break;
    case FontColor.GRAY:
      configStyles.color = Colors.grayDark;
      break;
    case FontColor.DISABLED:
    case FontColor.SECONDARY:
      configStyles.color = Colors.headerTextGray;
      break;
    case FontColor.BRIGHT:
      configStyles.color = Colors.headerTextPurple;
      break;
    case FontColor.PINK:
    case FontColor.HIGHLIGHT:
      configStyles.color = Colors.highlightedText;
      break;
    case FontColor.BLUE_DARK:
    case FontColor.PRIMARY:
      configStyles.color = Colors.bodyText;
      break;
    case FontColor.DARK_BACKGROUND:
      configStyles.color = Colors.white;
      break;
    case FontColor.DARK_BACKGROUND_SECONDARY:
      configStyles.color = Colors.bodyTextDarkSecondary;
      break;
    case FontColor.DARK_BACKGROUND_OFFWHITE:
      configStyles.color = Colors.bodyTextDarkTransparent;
      break;
    case FontColor.ERROR:
      configStyles.color = Colors.statusError;
      break;
    case FontColor.BENEPASS:
      configStyles.color = Colors.bodyTextBenepass;
      break;
    default:
      break;
  }

  switch (textCase) {
    case Cases.UPPERCASE:
      if (text instanceof Array) {
        text = text.map((t) => String(t).toUpperCase());
      } else {
        text = String(text).toUpperCase();
      }
      break;
    case Cases.LOWERCASE:
      if (text instanceof Array) {
        text = text.map((t) => String(t).toLowerCase());
      } else {
        text = String(text).toLowerCase();
      }
      break;
    default:
      break;
  }

  switch (weight) {
    case FontWeight.NORMAL:
      configStyles.fontWeight = 'normal';
      break;
    case FontWeight.MEDIUM:
      configStyles.fontWeight = 500;
      break;
    case FontWeight.SEMIBOLD:
      configStyles.fontWeight = 600;
      break;
    case FontWeight.BOLD:
      configStyles.fontWeight = 'bold';
      break;
    default:
      break;
  }

  const combinedStyles = {
    ...configStyles,
    ...userStyles,
    height: numberOfLines ? `${configStyles.lineHeight * numberOfLines}px` : undefined,
    lineHeight: `${configStyles.lineHeight}px`,
  };

  return (
    <div style={combinedStyles} className={className}>
      {text}
    </div>
  );
};

StyledText.defaultProps = {
  color: undefined,
  size: undefined,
  style: undefined,
  textCase: undefined,
  truncateLines: undefined,
  weight: undefined,
  className: undefined,
};

export default StyledText;
