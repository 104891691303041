// @flow
// $FlowIgnore
import { useFetchClient } from 'utils/src/services/DataFetchClient';
import { theme } from 'twin.macro';
import { CacheProvider } from 'rest-hooks';
// $FlowIgnore
import { ThemeProvider } from 'react-components';
// $FlowIgnore
import { useAuthentication } from '../../data/auth/transition';

// $FlowIgnore
import FetchContext from 'utils/src/context/FetchContext';
// $FlowIgnore
import MinScreenProvider from 'utils/src/hooks/use-min-screen';

import MobileAppStoresModal from '../modals/mobile-app-stores';

import type { Node } from 'react';

const Providers = ({ children }: { children: Node }): Node => {
  const client = useFetchClient(useAuthentication);

  return (
    <CacheProvider>
      <ThemeProvider>
        <MobileAppStoresModal>
          <FetchContext.Provider value={client}>
            <MinScreenProvider screens={theme`screens`}>{children}</MinScreenProvider>
          </FetchContext.Provider>
        </MobileAppStoresModal>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default Providers;
