// @flow
const FontSize = {
  BODY1: 'BODY1',
  BODY2: 'BODY2',
  BODY3: 'BODY3',
  BODY4: 'BODY4',
  BODY5: 'BODY5',
  BODY6: 'BODY6',
  BODY7: 'BODY7',
  BODY8: 'BODY8',
  BODY9: 'BODY9',
  H1: 'H1',
  H1B: 'H1B',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
  H5: 'H5',
  H6: 'H6',
};

export default FontSize;
