// @flow
// Color scheme (eng-design) TO DEPRECATE
const pinkBrightNew = '#F72585';
const purpleDarkBright = '#463090';
const purpleDarkerBright = '#211C5C';
const purpleLighterBright = '#735ac8'; // '#593db6';

// New Designs Color Scheme
// NOTE: vv used in Android notifications
const purpleDark = '#211E5B'; // 211C53 ?? 211C5C ??
const blueBenepass = '#1D8FCC';
const redLight = '#FEDBE7';
const pinkLight = '#F2CBD9';
const pinkDark = '#EB1963';
const greenLight = '#CAEBCA';
const greenDark = '#51A351';

// Benepass Colors Icons
const blueLight = '#C1D9E5';
const grayIconLight = '#E6E6E9';
const purpleLight = '#D7D6F3';

// graytones
const black = '#000000';
const grayDark = '#B4B3C2';
const grayMedium = '#D8D9DC';
const grayCatalogTransparent = 'rgba(255, 255, 255, 0.7)';
const grayCatalogTransparentLight = 'rgba(0, 0, 0, 0.4)';
const grayLight = '#F1F2F5';
const grayCatalogLighter = '#EFEFEF';
const grayCatalog = '#ECECEC'; // TODO: dedupe all these grays!
const grayCatalogLight = '#E5E5E5'; // EAEAEA
const grayCatalogDark = '#8B8B8B';
const grayCatalogDarker = '#858585';
const grayLightBackground = '#F8F9F9';
const grayCatalogPurple = '#A5A3C1';
const grayDarkBlue = '#454547';
const white = '#FFFFFF';

// other colors
const blueBright = '#32A2FF'; // progress bar
const blueInfo = '#EAF5FF';
const blueInfoDarker = '#32A1FF';
const dealGreenDark = '#34A853';
const dealGreenLight = 'rgba(52, 168, 83, 0.1)'; // 34A853 with 10% opacity
const green = '#35b858';
const greenSuccessLight = '#E0F6E0';
const greenBlueCatalog = '#0ECCA0';
const purpleInfoLight = '#F0EFFE';
const purpleInfoDark = '#453F93';
const orange = '#F2994A';
const red = '#F13737';
const redErrorDark = '#DF3333';
const redErrorLight = '#FAE4E4';
const yellow = '#b89935';
const yellowMustard = '#F2C94C';
const yellowWarningDark = '#EE9E27';
const yellowWarningLight = '#FAEEE4';
const confettiBlue = '#4FD0DE';
const confettiGreen = '#40CD66';
const confettiOrange = '#F5B555';
const confettiPink = '#E22390';
const confettiPurple = '#B742CC';

// TODO: grep for each of these and kill what isn't used
export default {
  black,
  blueLight,
  grayDark,
  backgroundDark: purpleDark,
  backgroundGray: grayLightBackground,
  backgroundGrayDark: grayIconLight,
  backgroundIcon: pinkDark,
  backgroundIconTransparent: grayCatalogTransparentLight,

  BENEFIT_BLUE: blueBenepass,
  BENEFIT_GRAY: grayDark,
  BENEFIT_GREEN: greenDark,
  BENEFIT_PINK: pinkDark,
  BENEFIT_PURPLE: purpleDark,
  ICON_BLUE: blueLight,
  ICON_GRAY: grayIconLight,
  ICON_GREEN: greenLight,
  ICON_PINK: pinkLight,
  ICON_PURPLE: purpleLight,

  bodyText: black,
  bodyTextBenepass: purpleDark,
  bodyTextInfo: blueInfoDarker,
  bodyTextSecondary: grayCatalogDark,
  bodyTextDarkSecondary: grayCatalogPurple,
  bodyTextDarkTransparent: grayCatalogTransparent,
  borderColor: grayMedium,
  borderColorLight: grayCatalogLighter,
  borderColorDark: grayDark,
  buttonConfirm: pinkBrightNew,
  buttonDefault: grayLight,
  buttonPrimary: blueInfoDarker,
  buttonText: white,

  cardBackground: grayLight,
  cardPhysical: blueLight,
  cardVirtual: purpleDark,
  cardTextCanceled: grayDark,

  confettiBlue,
  confettiGreen,
  confettiOrange,
  confettiPink,
  confettiPurple,

  contributionFamily: orange,
  contributionSingle: yellowMustard,
  copyBackground: grayCatalog,
  copyText: grayDarkBlue,
  dealBackground: dealGreenLight,
  dealButton: dealGreenDark,
  dealText: dealGreenDark,
  fab: pinkDark,
  iconActive: grayCatalogDarker,
  iconBack: purpleDark,
  iconBenefit: purpleDark,
  iconDarkBackground: grayCatalogPurple,
  iconError: red,
  iconInactive: grayDark, // #4A5568?
  iconInfo: blueInfoDarker,
  iconPrimary: purpleDark,
  iconSuccess: greenDark,
  infoBackground: blueInfo,
  headerBackground: purpleDarkBright,
  headerBackgroundGradientDarker: purpleDarkerBright,
  headerBackgroundGradientLighter: purpleLighterBright,
  headerButton: blueInfoDarker,
  headerText: black,
  headerTextGray: grayCatalogDark,
  headerTextPurple: purpleDarkBright,
  highlightedText: pinkBrightNew,
  loadingSpinner: pinkBrightNew,
  notificationSuccess: greenLight,
  notificationSuccessBorder: greenDark,
  notificationWarning: redLight,
  notificationWarningBorder: pinkDark,
  picker: white,
  // TODO: pick pill color
  pills: pinkBrightNew,
  pillButtons: purpleDark,
  progressCurrent: blueBright,
  progressTotal: grayCatalogLight,
  screenBackground: white,
  statusError: red,
  statusErrorFun: pinkBrightNew,
  statusSuccess: greenBlueCatalog,
  statusWarn: yellow,
  success: dealGreenDark,
  transactionCharge: pinkBrightNew,
  transactionReload: green,
  transactionZero: black,
  transactionStatusActionText: yellowWarningDark,
  transactionStatusActionBackground: yellowWarningLight,
  transactionStatusPendingText: blueBright, // blueInfoDark?
  transactionStatusPendingBackground: blueInfo,
  transactionStatusErrorText: redErrorDark,
  transactionStatusErrorBackground: redErrorLight,
  transactionStatusBenepassText: purpleInfoDark,
  transactionStatusBenepassBackground: purpleInfoLight,
  transactionStatusCompleteText: greenDark,
  transactionStatusCompleteBackground: greenSuccessLight,
  white,
  PERK: blueInfoDarker,
  MAIN: purpleDarkerBright,
};
