// @flow
const FontColor = {
  // TODO: DEPRECATE THESE
  BLUE_DARK: 'BLUE_DARK',
  BLUE_BRIGHT: 'BLUE_BRIGHT',
  GRAY: 'GRAY',
  PINK: 'PINK',

  // TODO: make this no longer blue
  BRIGHT: 'bright',
  BENEPASS: 'benepass',

  // Better Usage Colors
  DISABLED: 'disabled',
  DARK_BACKGROUND: 'dark_background',
  DARK_BACKGROUND_SECONDARY: 'dark_background_secondary',
  DARK_BACKGROUND_OFFWHITE: 'dark_background_offwhite',
  ERROR: 'error',
  HEADER: 'header',
  HIGHLIGHT: 'highlight',
  LINK: 'link',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export default FontColor;
