// @flow
import type { Node } from 'react';
import { useEffect } from 'react';
import { NetworkError } from 'rest-hooks';
// $FlowIgnore
import { useAuthentication } from '../data/auth/transition';

import StyledText from './components/StyledText';

import FontColor from '../utils/FontColor';
import FontSize from '../utils/FontSize';

// fill in object with local props if needed

const styles = {
  container: {
    display: 'flex',
    flex: 1,
  },
  content: {
    padding: '24px',
  },
  header: {
    alignItems: 'center',
  },
  section: {
    marginTop: '20px',
    flex: 1,
  },
};
type Props = {
  error: $Values<typeof NetworkError>,
};

function NetworkFailureDash({ error }: Props): Node {
  const { status, response } = error;
  const statusText = response?.statusText;
  const statusMessage = statusText || 'No status text available';
  const { isAuthenticated, loginWithRedirect } = useAuthentication();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect]);

  return (
    <div style={styles.container} data-testid="network-failure-dash">
      <div>
        <div style={styles.content}>
          <div style={styles.header}>
            <StyledText color={FontColor.BLUE_BRIGHT} size={FontSize.H3}>
              Hey, there!
            </StyledText>
          </div>
          <div style={styles.section}>
            <StyledText size={FontSize.BODY2}>
              Something has gone wrong while trying to load your data. Please try again in a minute. If that does not
              work, please contact us for help! Thank you and sorry for the trouble.
            </StyledText>
          </div>
          <div style={styles.section}>
            <StyledText color={FontColor.SECONDARY}>
              {`Error status: ${status}, Status text: ${statusMessage}`}
            </StyledText>
          </div>
          {/* TODO: add sad but cute illustration */}
        </div>
      </div>
    </div>
  );
}

export default NetworkFailureDash;
