// @flow
import { useState, useEffect } from 'react';
// $FlowIgnore
import { useAuthCheck as useAuthCheckNormal } from '../data/auth/Auth';
import Status from './FetchStatus';

type Result = {
  status: $Values<typeof Status>,
};

const useAuthCheckCypress = (): Result => {
  const [status, setStatus] = useState(Status.loading);

  useEffect(() => {
    (async () => {
      const auth0 = JSON.parse(window.localStorage.getItem('auth0Cypress'));
      const token = auth0.body.access_token;
      // TODO: add an easy way for cypress to setup the state machine
      // auth.setAccessToken(token);
      localStorage.setItem('access_token', token);
      setStatus(Status.success);
    })();
  }, []);
  return { status };
};

const useAuthCheck: () => Result = window.Cypress ? useAuthCheckCypress : useAuthCheckNormal;

export { Status, useAuthCheck };
