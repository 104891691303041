// @flow
import _ from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import authenticationMachine from './machine/machine';

import { interpret } from 'xstate';

const AUTHENTICATED = ['loggedIn'];
const LOADING = ['start', 'authenticating'];
const inState = (states, state) => states.some(state.matches);

const auth = interpret(authenticationMachine);

export const getAccessToken = async (): Promise<string> => auth.getSnapshot().context.id_token;
export const getAccessTokenSync = (): string => auth.getSnapshot().context.id_token;
export const loginWithRedirect = () => {
  auth.send('logout');
};
export const logout = () => {
  auth.send('logout');
};

export const AuthenticationContext: any = createContext({});

export const useAuthentication = (): any => {
  return useContext(AuthenticationContext);
};

export const useAuthCheck = (): { status: string } => {
  const state = auth.getSnapshot();
  if (inState(AUTHENTICATED, state)) {
    return { status: 'success' };
  }
  return { status: state.matches('error') ? 'error' : 'loading' };
};

export const AuthenticationProvider = ({ children }: { children: any }): any => {
  const [authParams, setAuthParams] = useState({
    isLoading: true,
    isAuthenticated: false,
    getAccessToken,
    logout,
    loginWithRedirect,
  });
  useEffect(() => {
    const listener = (state) => {
      const { user } = state.context;
      const temp = {
        user,
        isAuthenticated: inState(AUTHENTICATED, state),
        isLoading: inState(LOADING, state),
        getAccessToken,
        logout,
        loginWithRedirect,
      };
      if (!_.isEqual(authParams, temp)) {
        setAuthParams(temp);
      }
    };
    const subscription = auth.subscribe(listener);
    return () => subscription.unsubscribe();
  }, [authParams, setAuthParams]);
  useEffect(() => auth.start(), []);

  return <AuthenticationContext.Provider value={authParams}>{children}</AuthenticationContext.Provider>;
};
