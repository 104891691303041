// @flow
import { generatePath } from 'react-router-dom';

import type { Node } from 'react';

export type Route = {
  route: string,
  name: string,
  icon: Node,
  enabled: boolean,
};

export const getPathByPattern = (pattern: string, attributes: any = {}): string => {
  return generatePath(pattern, attributes);
};

const Routes = {
  // Root
  HOME: '/',
  HOME_COMPANY_CARDS: '/card-company/:cardId',
  HOME_HSA_CARDS: '/card-hsa/:cardId',
  HOME_CARDS: '/card-:cardType/:cardId',
  HOME_REQUEST_CARD: '/card-:cardType/:cardId/request-card',
  HOME_UPDATE_BILLING_ADDRESS: '/card-:cardType/:cardId/update-address',
  HOME_REQUEST_CARD_SUCCESS: '/card-:cardType/:cardId/request-card/success',
  HOME_NEW_EXPENSE: '/new-expense',
  HOME_BENEFIT: '/benefits/:benefitId',

  // Core Screens
  ACCOUNT: '/account',
  EXPLORE: '/explore',
  MERCHANTS: '/merchants',
  EXPLORE_NEW_EXPENSE: '/explore/new-expense',
  EXPLORE_OFFERS: '/explore/offers/:offerId',
  TRANSACTIONS: '/transactions',
  TRANSACTIONS_NEW_EXPENSE: '/transactions/new-expense',
  TRANSACTIONS_DETAILS: '/transactions/:transactionId',
  REIMBURSEMENTS: '/reimbursements',
  REIMBURSEMENT_PAYOUT_DETAILS: '/reimbursements/:accountId/:transactionId/',
  REIMBURSEMENT_EXPENSE_DETAILS: '/reimbursements/expense/:transactionId/',
  DOCUMENTS: {
    MAIN: '/documents',
    TAX_FORMS: '/documents/forms',
    HSA_STATEMENTS: '/documents/hsa-statements',
  },
  SETTINGS: '/settings',
  HSA: '/hsa',
  HSA_INVESTMENTS: '/hsa/investments',
  HSA_INVESTMENTS_MYHSA: '/hsa/investments-myhsa',
  HSA_INVESTMENTS_SCHWAB: '/hsa/investments-schwab',
  HSA_CONTRIBUTIONS: '/hsa/contributions',
  HSA_BENEFIT: '/hsa/benefits/:benefitId',
  ONBOARDING: '/welcome',
  ONBOARDING_EDIT_ADDRESS: '/welcome/edit-address',
};

export default Routes;
