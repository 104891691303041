// @flow
import type { Node } from 'react';
import { Suspense, useCallback } from 'react';
// $FlowIgnore
import { AuthenticationContext } from '../../data/auth/transition';
import { NetworkErrorBoundary } from 'rest-hooks';
// $FlowIgnore
import { OverlayLoader, PageError, Message } from 'react-components';
// $FlowIgnore
import Button from 'react-components/src/components/button';
import current from '../../config';
// $FlowIgnore
import Link from 'react-components/src/components/link';

import NetworkFailureDash from '../NetworkFailureDash';
// $FlowIgnore
import { openTicketLink } from 'utils/src/Support';

import { Status, useAuthCheck } from '../../utils/auth';

type ErrorPageProps = {
  logout?: Function,
  children?: Node,
  user?: {
    email: string,
  },
};

const { MessageTypes } = Message;
const ErrorPage = ({ logout, children, user }: ErrorPageProps): Node => {
  const handleLogout = useCallback(() => {
    logout?.();
  }, [logout]);
  return (
    <PageError title="Invalid Email">
      {user?.email && (
        <p className="text-gray-500">
          Logged in as: <strong>{user.email}</strong>
        </p>
      )}
      <Message type={MessageTypes.ERROR}>
        That email isn’t registered with a Benepass account. Check for typos or please{' '}
        <Link size="md" className="underline" href={openTicketLink} shouldOpenNewTab>
          submit a ticket to our support
        </Link>
        .
      </Message>
      {children}
      <Button className="bg-red-100 justify-center" onClick={handleLogout}>
        Logout
      </Button>
    </PageError>
  );
};

ErrorPage.defaultProps = {
  logout: undefined,
  children: undefined,
  user: undefined,
};

type AuthCheckerProps = {
  children: Node,
  error?: string, // eslint-disable-line
  logout?: Function, // eslint-disable-line
  user?: { email: string }, //eslint-disable-line
};

const AuthChecker = ({ logout, error, children, user }: AuthCheckerProps): Node => {
  const { status } = useAuthCheck();
  if (status === Status.loading) {
    return <OverlayLoader message="Fetching user data..." />;
  }
  if (status === Status.success) {
    return (
      <Suspense fallback={<OverlayLoader message="Loading..." />}>
        <NetworkErrorBoundary fallbackComponent={NetworkFailureDash}>{children}</NetworkErrorBoundary>
      </Suspense>
    );
  }
  return (
    <ErrorPage logout={logout} user={user}>
      {error}
    </ErrorPage>
  );
};

AuthChecker.defaultProps = {
  logout: undefined,
  error: undefined,
  user: undefined,
};

type AuthenticatorProps = {
  loading?: boolean,
};

const Authenticator = (props: AuthenticatorProps & AuthCheckerProps): Node => {
  const { children } = props;
  if (current.test) {
    return children;
  }
  return (
    <AuthenticationContext.Consumer>
      {({ isLoading, isAuthenticated, logout, user }) => {
        const loadingComponent = <OverlayLoader message="Authenticating user..." />;
        if (isLoading) {
          return loadingComponent;
        }
        if (!isAuthenticated) {
          localStorage.removeItem('access_token');
          return loadingComponent;
        }
        // eslint-disable-next-line
        return <AuthChecker {...props} user={user} logout={logout} />;
      }}
    </AuthenticationContext.Consumer>
  );
};

Authenticator.defaultProps = {
  loading: undefined,
};

export default Authenticator;
