// @flow
import type { Node } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { useEffect, Suspense } from 'react';
// $FlowIgnore
import { useAuthentication } from './data/auth/transition';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
// $FlowIgnore
import { createEnvironment } from 'utils/src/RelayEnvironment';
// $FlowIgnore
import { OverlayLoader, PageError, Message } from 'react-components';
import { ErrorBoundary } from 'react-error-boundary';
// $FlowIgnore
import { LogCategories, errorHandler } from 'utils/src/Logs';

import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';

import Authenticator from './ui/components/Authenticator';
import Routes from './routes';
import Providers from './ui/layout/providers';
import current from './config';
// $FlowIgnore
import { contactSupportMessage } from 'utils/src/Support';

import { version } from '../package.json';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const { MessageTypes } = Message;

const AppMain = (): Node => {
  const { user, getAccessToken } = useAuthentication();

  const relayEnvironment = createEnvironment(current.api.graphql, getAccessToken, user?.email, {
    'x-benepass-platform': 'web',
    'x-benepass-version': process.env.npm_package_version || version,
    'x-benepass-app': 'employee',
  });

  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
      LogRocket.identify(user.sub, user);
    }
  }, [user]);

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <Router>
        <Suspense fallback={<OverlayLoader message="Loading your data..." />}>
          <ErrorBoundary
            FallbackComponent={() => (
              <PageError title="Server Unreachable">
                <Message type={MessageTypes.ERROR}>
                  Something is wrong on Benepass Servers. {contactSupportMessage}
                </Message>
              </PageError>
            )}
            onError={errorHandler(LogCategories.UNKNOWN_ERROR)}
          >
            <Routes />
          </ErrorBoundary>
        </Suspense>
      </Router>
    </RelayEnvironmentProvider>
  );
};

const App = (): Node => (
  <Authenticator>
    <Providers>
      <AppMain />
    </Providers>
  </Authenticator>
);

export default App;
