// @flow
import { useEffect, lazy, Suspense, useMemo } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
// $FlowIgnore
import { LogCategories, logHandler, errorHandler } from 'utils/src/Logs';
// $FlowIgnore
import { PageError, Message, Loading } from 'react-components';
import { ErrorBoundary } from 'react-error-boundary';
import { usePreloadedQuery } from 'react-relay';
import { Helmet } from 'react-helmet';

// $FlowIgnore
import useAnalytics from 'utils/src/hooks/useAnalytics';
// $FlowIgnore
import UnknownError from 'react-components/src/components/alert/unknown-error';
import graphql from 'babel-plugin-relay/macro';
// $FlowIgnore
import useQuery from 'utils/src/hooks/useQuery';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import RootLayout from '../ui/layout/RootLayout';
import RoutesEnum from '../enums/RoutesEnum';

import type { routesQuery as RoutesQueryType } from './__generated__/routesQuery.graphql';
import type { PreloadedQuery } from 'react-relay';
import type { Node } from 'react';
// $FlowIgnore
import { contactSupportMessage } from 'utils/src/Support';

const OnboardingPage = lazy(() => import('../ui/pages/onboarding/OnboardingPage'));

const HomeRoutes = lazy(() => import('./home'));
const HSARoutes = lazy(() => import('./hsa'));
const ExploreRoutes = lazy(() => import('./explore'));
const MerchantsRoutes = lazy(() => import('./merchants'));
const ModalsRoutes = lazy(() => import('./modals'));
const TransactionsRoutes = lazy(() => import('./transactions'));
const DocumentsRoutes = lazy(() => import('./documents'));
const SettingsRoutes = lazy(() => import('./settings'));
const ReimbursementsRoutes = lazy(() => import('./reimbursements'));

const { MessageTypes } = Message;

const RoutesQuery = graphql`
  query routesQuery {
    viewer {
      apiID
      fullName
      email
    }

    ...RootLayoutFragment
  }
`;

const RoutesWrapper = (): Node => {
  const queryReference = useQuery<RoutesQueryType>(RoutesQuery, {});

  if (!queryReference) {
    return null;
  }

  return (
    <ErrorBoundary FallbackComponent={() => <UnknownError />} onError={logHandler(LogCategories.UNKNOWN_GRAPHQL)}>
      <Routes queryReference={queryReference} />
    </ErrorBoundary>
  );
};

type Props = {
  queryReference: PreloadedQuery<RoutesQueryType>,
};

const Routes = ({ queryReference }: Props): Node => {
  const query = usePreloadedQuery<RoutesQueryType>(RoutesQuery, queryReference);
  const location = useLocation();
  const analytics = useAnalytics();

  const previousUrl = useMemo(() => location.pathname, [location?.pathname]);

  const { viewer } = query;

  useEffect(() => {
    analytics.page(location.pathname, {
      search: location.search,
      previousPath: previousUrl,
    });
  }, [previousUrl, analytics, location]);

  useEffect(() => {
    analytics.identify(viewer?.apiID, {
      email: viewer?.email,
      name: viewer?.fullName,
    });
  }, [analytics, viewer]);

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <PageError title="Server Unreachable">
          <Message type={MessageTypes.ERROR}>Something is wrong on Benepass Servers. {contactSupportMessage}</Message>
        </PageError>
      )}
      onError={errorHandler(LogCategories.UNKNOWN_ERROR)}
    >
      <Switch>
        <Route exact path={[RoutesEnum.ONBOARDING, `${RoutesEnum.ONBOARDING}/(.*)`]}>
          <Helmet>
            <title>Welcome - Benepass</title>
          </Helmet>

          <OnboardingPage />
        </Route>

        <RootLayout query={query}>
          <Switch>
            <Suspense fallback={<Loading />}>
              <SettingsRoutes />
              <TransactionsRoutes />
              <DocumentsRoutes />
              <HomeRoutes />
              <HSARoutes />
              <ExploreRoutes />
              <MerchantsRoutes />
              <ReimbursementsRoutes />
            </Suspense>
          </Switch>

          <Suspense fallback={null}>
            <ModalsRoutes />
          </Suspense>
        </RootLayout>
      </Switch>
    </ErrorBoundary>
  );
};

export default RoutesWrapper;
