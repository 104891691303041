// @flow
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { useFragment } from 'react-relay/hooks';
// $FlowIgnore
import { useAuthentication } from '../../data/auth/transition';

import graphql from 'babel-plugin-relay/macro';
// $FlowIgnore
import * as Page from 'react-components/src/ui/layout/page';
// $FlowIgnore
import * as Sidebar from 'react-components/src/ui/sidebar';
// $FlowIgnore
import * as Icons from '@benepass/icons/src/components/Icon';
// $FlowIgnore
import Link from 'react-components/src/components/link';
// $FlowIgnore
import { openTicketLink } from 'utils/src/Support';
import { coolgray } from '@benepass/colors';

import RoutesEnum from '../../enums/RoutesEnum';

import type { Node } from 'react';
import type { RootLayoutFragment$key as QueryType } from './__generated__/RootLayoutFragment.graphql';

const fragment = graphql`
  fragment RootLayoutFragment on Query {
    viewer {
      hasHSA
      canLinkBankAccount
      benepassCashAccount
    }
  }
`;

type Props = {
  children: Node,
  query: QueryType,
};

const RootLayout = ({ children, query }: Props): Node => {
  const location = useLocation();
  const history = useHistory();

  const data = useFragment(fragment, query);
  const generateKey = (route) => `sidebar_route_${route.key}`;

  const routes = [
    {
      key: 'home',
      children: (
        <>
          <Sidebar.Button.Icon>
            <Icons.Home />
          </Sidebar.Button.Icon>
          <Sidebar.Button.Text>Home</Sidebar.Button.Text>
        </>
      ),
      route: RoutesEnum.HOME,
      enabled: true,
      enabledRoutes: [
        RoutesEnum.HOME,
        RoutesEnum.HSA,
        RoutesEnum.HOME_COMPANY_CARDS,
        RoutesEnum.HOME_HSA_CARDS,
        RoutesEnum.HOME_REQUEST_CARD,
        RoutesEnum.HOME_REQUEST_CARD_SUCCESS,
        RoutesEnum.HOME_NEW_EXPENSE,
        RoutesEnum.HOME_BENEFIT,
        RoutesEnum.HSA_INVESTMENTS,
        RoutesEnum.HSA_INVESTMENTS_MYHSA,
      ],
    },
    {
      key: 'explore',
      children: (
        <>
          <Sidebar.Button.Icon>
            <Icons.Sparkle />
          </Sidebar.Button.Icon>
          <Sidebar.Button.Text>Explore</Sidebar.Button.Text>
        </>
      ),
      route: RoutesEnum.EXPLORE,
      enabled: true,
      enabledRoutes: [RoutesEnum.EXPLORE, `${RoutesEnum.EXPLORE}/(.*)`, `${RoutesEnum.MERCHANTS}/(.*)`],
    },
    {
      key: 'transactions',
      children: (
        <>
          <Sidebar.Button.Icon>
            <Icons.Cash />
          </Sidebar.Button.Icon>
          <Sidebar.Button.Text>Transactions</Sidebar.Button.Text>
        </>
      ),
      route: RoutesEnum.TRANSACTIONS,
      enabled: true,
      enabledRoutes: [RoutesEnum.TRANSACTIONS, RoutesEnum.TRANSACTIONS_NEW_EXPENSE, RoutesEnum.TRANSACTIONS_DETAILS],
    },
    {
      key: 'reimbursements',
      children: (
        <>
          <Sidebar.Button.Icon>
            <Icons.Reimbursement />
          </Sidebar.Button.Icon>
          <Sidebar.Button.Text>Reimbursements</Sidebar.Button.Text>
        </>
      ),
      route: RoutesEnum.REIMBURSEMENTS,
      enabled: !!data.viewer?.canLinkBankAccount && !!data.viewer?.benepassCashAccount,
      enabledRoutes: [
        RoutesEnum.REIMBURSEMENTS,
        RoutesEnum.REIMBURSEMENT_EXPENSE_DETAILS,
        RoutesEnum.REIMBURSEMENT_PAYOUT_DETAILS,
      ],
    },
    {
      key: 'documents',
      children: (
        <>
          <Sidebar.Button.Icon>
            <Icons.Report />
          </Sidebar.Button.Icon>
          <Sidebar.Button.Text>Documents</Sidebar.Button.Text>
        </>
      ),
      route: RoutesEnum.DOCUMENTS.MAIN,
      enabled: data.viewer?.hasHSA || false,
      enabledRoutes: Object.values(RoutesEnum.DOCUMENTS),
    },
    {
      key: 'settings',
      children: (
        <>
          <Sidebar.Button.Icon>
            <Icons.Engine />
          </Sidebar.Button.Icon>
          <Sidebar.Button.Text>Settings</Sidebar.Button.Text>
        </>
      ),
      route: RoutesEnum.SETTINGS,
      enabled: true,
      enabledRoutes: [RoutesEnum.SETTINGS],
    },
  ].filter((value) => value.enabled);

  return (
    <Page.Container>
      <Sidebar.Root>
        <Sidebar.ButtonGroup>
          {routes.map((route) => (
            <Sidebar.Button
              key={generateKey(route)}
              id={generateKey(route)}
              active={route.enabledRoutes?.some((path) => matchPath(location.pathname, { path, exact: true }))}
              onClick={() => history.push(route.route)}
            >
              {route.children}
            </Sidebar.Button>
          ))}
        </Sidebar.ButtonGroup>
        <Footer />
      </Sidebar.Root>

      <Page.Root className="bg-grayscale-2">{children}</Page.Root>
    </Page.Container>
  );
};

const Footer = () => {
  const { logout, user } = useAuthentication();
  const { isCollapsed } = Page.usePageContainer();
  return (
    <Sidebar.Footer>
      <Sidebar.Footer.Avatar name={user?.name} className={isCollapsed ? 'hidden' : 'block'} />
      <Sidebar.Footer.Group>
        <Sidebar.Footer.Name>{user?.name}</Sidebar.Footer.Name>
      </Sidebar.Footer.Group>

      <Sidebar.Dropdown>
        <Sidebar.Dropdown.Item>
          <Link href={openTicketLink} size="md" shouldOpenNewTab>
            Contact Support
          </Link>
        </Sidebar.Dropdown.Item>

        <Sidebar.Dropdown.Item onSelect={logout}>Logout</Sidebar.Dropdown.Item>

        <Sidebar.Dropdown.Trigger>
          {isCollapsed ? (
            <Sidebar.Footer.Avatar name={user?.name} size="sm" className="mr-1" />
          ) : (
            <Icons.AngleDown size="16px" color={coolgray[100]} />
          )}
        </Sidebar.Dropdown.Trigger>
      </Sidebar.Dropdown>
    </Sidebar.Footer>
  );
};

export default RootLayout;
