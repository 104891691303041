import React from 'react';
import type { ReactNode, PropsWithChildren } from 'react';
import AnalyticsContext from '../context/AnalyticsContext';
import type { AnalyticsContextValue } from '../context/AnalyticsContext';

const { useContext, useEffect, useMemo } = React;
type Props = PropsWithChildren<{ gtagId?: string }>;

const analyticsWindow = window as typeof window & {
  dataLayer: any;
  gtag: any;
};

export const AnalyticsProvider = ({ children, gtagId }: Props): ReactNode => {
  // initialize google tag manager
  useEffect(() => {
    if (!gtagId) {
      return;
    }

    analyticsWindow.dataLayer = analyticsWindow.dataLayer || [];
    analyticsWindow.gtag = (...args: any[]) => {
      analyticsWindow.dataLayer.push(args);
    };
    analyticsWindow.gtag('js', new Date());
    analyticsWindow.gtag('config', gtagId);

    const head = window.document.querySelector('head');
    if (!head) {
      return;
    }

    const injectGtagScript = window.document.createElement('script');
    injectGtagScript.async = true;
    injectGtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
    head.appendChild(injectGtagScript);
  }, [gtagId]);

  const value = useMemo(
    () => ({
      page: (path: string, params?: Record<string, unknown>) => {
        analyticsWindow.gtag?.('event', 'page_view', {
          page_title: path,
          page_location: path + params?.search,
          page_path: path,
          send_to: gtagId,
        });
      },
      identify: (id?: string) => {
        if (id) {
          analyticsWindow.gtag?.('config', gtagId, { user_id: id });
        }
      },
      track: (name: string, params?: Record<string, unknown>) => {
        analyticsWindow.gtag?.('event', name, params);
      },
    }),
    [gtagId]
  );

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

AnalyticsProvider.defaultProps = {
  gtagId: undefined,
};

export const useAnalytics = (): AnalyticsContextValue => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be used with AnalyticsProvider');
  }

  return context;
};

export default useAnalytics;
